import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ListBrand from '../../components/list-brand';
import ListLink from '../../components/list-link';
import Image from '../../components/Image';

const AccessoryPage = () => (
    <Layout page="accessory-page">
      <Seo title="ACCESSORY"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>ACCESSORIES</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title"><span>薪ストーブをより楽しむためのアクセサリ</span></h3>
            <p>
              薪ストーブのある豊かな暮らしには欠かせないメンテナンスに関わるストーブアクセサリ、機能性の高い薪のためのツール。料理に使えるアクセサリ。<br />
              多くの一流メーカー品が薪ストーブのある家の中を演出します。
            </p>
          </div>
          <div className="content-block accessory-bg">
            <div className="accessory-list">
              <div className="md:flex">
                <Link to="/accessory/detail/?id=outdoor" className="accessory-item">
                  <p className="read"><span>炎が持つぬくもりだけでなく</span><br /><span>アウトドアの雰囲気を</span></p>
                  <Image filename="accessory/accessory-01.png" />
                  <p className="name">OUTDOOR<span>アウトドア</span></p>
                </Link>
                <Link to="/accessory/detail/?id=cooking" className="accessory-item">
                  <p className="read"><span>ストーブライフの</span><br /><span>もうひとつの楽しみ方</span></p>
                  <Image filename="accessory/accessory-02.png" />
                  <p className="name">COOKING<span>クッキング</span></p>
                </Link>
              </div>
              <div className="md:flex">
                <Link to="/accessory/detail/?id=daily-use" className="accessory-item">
                  <p className="read"><span>薪への着火作業を</span><br /><span>簡単・確実・安全に</span></p>
                  <Image filename="accessory/accessory-03.png" />
                  <p className="name">DAILY USE<span>デイリーユース</span></p>
                </Link>
              </div>
              <div className="md:flex justify-end">
                <Link to="/accessory/detail/?id=maintenance" className="accessory-item">
                  <p className="read"><span>ストーブをいつもまでも</span><br /><span>お使いいただくために</span></p>
                  <Image filename="accessory/accessory-04.png" />
                  <p className="name">MAINTENANCE<span>メンテナンス＆ケア</span></p>
                </Link>
                <Link to="/accessory/detail/?id=axe-firewood" className="accessory-item">
                  <p className="read"><span>薪を、より安全で快適に</span><br /><span>お使いただくために</span></p>
                  <Image filename="accessory/accessory-05.png" />
                  <p className="name">AXE & FIREWOOD<span>斧&薪</span></p>
                </Link>
              </div>
            </div>
          </div>
          <div className="content-block">
            <a className="btn pdf full" href="/update/pdf/accessory.pdf" target="_blank">
              PDFカタログ ダウンロード
            </a>
          </div>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>アクセサリ</li>
        </ul>
      </section>
    </Layout>
);

export default AccessoryPage;
